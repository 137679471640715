
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';

import QuantityTicker from '@/common/components/quantity-ticker.component.vue';
import ActivitySelector from '../components/activity-selector.component.vue';

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { EntityHierarchyRate } from '@/common/models/EntityHierarchyRate.model';

@Options({
	name: 'RateView',
	components: { QuantityTicker, ActivitySelector },
})
export default class RateView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	created() {
		orderModule.setOpenActivity(this.item?.Activities[0].EntityHierarchyKey ?? 0);
		//set participants to min participants
		this.activity?.rates.forEach((r) => (r.Participants = r.MinForPrivateTourOnly ? 0 : r.MinParticipants ?? 0));
	}
	get item(): Item | null {
		return orderModule.openItem;
	}
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get isPackage(): boolean {
		return this.item?.PackageKey != null;
	}
	get canProceed(): boolean {
		return (this.activity?.selectedRates.length ?? 0) > 0;
	}
	updateRate(rate: EntityHierarchyRate, participants: number): void {
		console.log('Setting rate from', rate.Participants, 'to', participants);
		rate.Participants = participants;
		orderModule.resetAvailability();
	}
	next(): void {
		if (orderModule.allRatesSelected) {
			this.$router.push(`/portal/${this.clientLocationKey}/${this.type}/${this.typekey}/book/date`);
		} else if ((this.activity?.selectedRates.length ?? 0) > 0) {
			let nextUnfinished = orderModule.itemActivities.find((activity) => activity.selectedRates.length == 0);
			orderModule.setOpenActivity(nextUnfinished?.entityHierarchyKey ?? 0);
		}
	}
}
